import React from 'react';
import {CommonIcons} from '../../styles/images';

const DownloadLink = () => {
  return (
    <div className="flex flex-row justify-center mt-10">
      <a target="blank" href="https://apps.apple.com/lk/app/zern/id1619242803">
        <img
          src={CommonIcons.appleDownloadBadge}
          alt="apple download badg"
          style={{height: 50, margin: 10}}
        />
      </a>
      <a
        target="blank"
        href="https://play.google.com/store/apps/details?id=com.sescube.zern">
        <img
          src={CommonIcons.googlePlayBadge}
          alt="google download badge"
          style={{height: 70}}
        />
      </a>
    </div>
  );
};

export default DownloadLink;
