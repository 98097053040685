import React from 'react';

const DeleteAccount = () => {
  return (
    <div className="px-10 sm:px-48 py-10 w-full">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdSrJga9n3qqCq7Vv_fzutiBRCVRIzNFR40C1XUBxyRyqjNHQ/viewform?embedded=true"
        height="927"
        width="100%"
        frameborder="0"
        marginheight="0"
        marginwidth="0">
        Loading…
      </iframe>
    </div>
  );
};

export default DeleteAccount;
