import React from 'react';
import DownloadLink from '../DownloadLink';
import {CommonIcons} from '../../styles/images';
const Carousel = () => {
  return (
    <div>
      <div
        className="bg-[#000] grid md:grid-cols-1 grid-cols-1 sm:grid-cols-1 px-0 lg:px-0 md:px-0"
        style={{height: '30rem'}}>
        <div className="md:ml-30 md:ml-0 sm:ml-0 grid grid-cols-1 h-fit px-10 mt-10 md:mt-10 sm:px-20">
          <div className="font-bold md:text-7xl text-3xl flex justify-center text-center text-white">
            Promote and <br />
            engage
          </div>
          <div className="lg:text-1xl text-lg mt-5 font-bold text-white  text-center flex justify-center">
            Find the best business and services for you needs. <br />
            Download the app today!
          </div>
        </div>
      </div>
      <div style={{marginTop: '-12rem'}}>
        <div className="flex justify-center hidden sm:block h-fit">
          <img
            src={CommonIcons.zernLanding}
            style={{maxHeight: '30rem', margin: 'auto'}}
          />
        </div>
        <DownloadLink />
      </div>
    </div>
  );
};

export default Carousel;
