import React from 'react';

const Footer = () => {
  return (
    <div className="bg-[#000] px-10 sm:px-48 py-10 w-full">
      <div className="grid grid-cols-1 sm:grid-cols-5 gap-1">
        <div>
          <div className="text-white font-bold py-3">Company</div>
          {/* <div><a href="about" className="text-gray-400 text-sm py-2">About</a></div> */}
          <div>
            <a
              href="/support/contact-us"
              className="text-gray-400 text-sm py-2">
              Contact us
            </a>
          </div>
        </div>

        <div className="col-span-2">
          <div className="text-white font-bold py-3">Legal</div>
          <a href="privacy-policy" className="text-gray-400 text-sm py-2">
            Privacy policy
          </a>
        </div>
      </div>
      <div className="mt-20 text-white font-bold">
        Copyright © 2024 all rights reserved Zern
      </div>
    </div>
  );
};

export default Footer;
