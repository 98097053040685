import appleDownloadBadge from '../assets/images/apple-download-badge.svg';

export const AppIcon = {
  forward: require('../assets/images/forward.png'),
  back: require('../assets/images/back.png'),
  close: require('../assets/images/close.png'),
  filter: require('../assets/images/filter.png'),
  verticalDots: require('../assets/images/3dots.png'),
  cross: require('../assets/images/cross.png'),
  zernLogo: require('../assets/zern-logo2.png'),
};

export const TabIcons = {
  home_outline: require('../assets/images/home.png'),
  home_filled: require('../assets/images/home-filled.png'),
  notificationCenterFilled: require('../assets/images/notification-center-filled.png'),
  notificationCenter: require('../assets/images/notification-center.png'),
  user: require('../assets/images/user.png'),
  userFilled: require('../assets/images/user-filled.png'),
  search: require('../assets/images/search.png'),
  profile: require('../assets/images/search.png'),
  addUser: require('../assets/images/addUser.png'),
  addUserFilled: require('../assets/images/addUserFilled.png'),
  dashboard: require('../assets/images/dashboard.png'),
  dashboardFilled: require('../assets/images/dashboard-filled.png'),
  settings: require('../assets/images/settings.png'),
  settingsFilled: require('../assets/images/settings-filled.png'),
  qrCode: require('../assets/images/qrCode.png'),
  qrCodeFilled: require('../assets/images/qrCode.png'),
  wallet: require('../assets/images/wallet.png'),
  walletFilled: require('../assets/images/wallet-filled.png'),
  commercial: require('../assets/images/commercial.png'),
  commercialFilled: require('../assets/images/commercial-filled.png'),
  chat: require('../assets/images/chat.png'),
  chatFilled: require('../assets/images/chat-filled.png'),
  account: require('../assets/images/account.png'),
  accountFilled: require('../assets/images/account-filled.png'),
  smallBusiness: require('../assets/images/small-business.png'),
  smallBusinessFilled: require('../assets/images/small-business-filled.png'),
};

export const CommonIcons = {
  rightArrow: require('../assets/images/rightArrow.png'),
  imagePlaceholder: require('../assets/images/img-placeholder.png'),
  share: require('../assets/images/share.png'),
  checkMark: require('../assets/images/checkmark.png'),
  language: require('../assets/images/language.png'),
  password: require('../assets/images/password.png'),
  logout: require('../assets/images/logout.png'),
  mail: require('../assets/images/mail.png'),
  call: require('../assets/images/call.png'),
  internet: require('../assets/images/internet.png'),
  clock: require('../assets/images/clock.png'),
  bug: require('../assets/images/bug.png'),
  error: require('../assets/images/error.png'),
  eyeClose: require('../assets/images/eye-close.png'),
  eyeOpen: require('../assets/images/eye-open.png'),
  starUnfilled: require('../assets/images/star-unfilled.png'),
  starFilled: require('../assets/images/star-filled.png'),
  chevronDown: require('../assets/images/chevron-down.png'),
  chevronUp: require('../assets/images/chevron-up.png'),
  coupons: require('../assets/images/coupons.png'),
  promotion: require('../assets/images/promotion.png'),
  business: require('../assets/images/business.png'),
  sweeping: require('../assets/images/sweeping.png'),
  googlePlayBadge: require('../assets/images/google-play-badge.png'),
  appleDownloadBadge: appleDownloadBadge,
  zernLanding: require('../assets/images/zern-landing.webp'),
  zernLandingMobile: require('../assets/images/zern-landing-mobile.webp'),
  bgDustRight: require('../assets/images/bg-dust-right.webp'),
  map: require('../assets/images/map.png'),
  list: require('../assets/images/list.png'),
  send: require('../assets/images/send.png'),
  rental: require('../assets/images/rental.png'),
  repair: require('../assets/images/repair.png'),
  construction: require('../assets/images/construction.png'),
  shopping: require('../assets/images/shopping.png'),
  restaurant: require('../assets/images/restaurant.png'),
  marker: require('../assets/images/marker.png'),
  image: require('../assets/images/image.png'),
  camera: require('../assets/images/camera.png'),
  plus: require('../assets/images/plus.png'),
  loading: require('../assets/images/loading.png'),
  exclamation: require('../assets/images/exclamation.png'),
};

export const CommonIllustration = {
  noResults: require('../assets/images/noResults.png'),
};
