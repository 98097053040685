import React from 'react';

export default function NavBar() {
  return (
    <div className="relative bg-[#000] py-3">
      <a href="#" className="text-3xl justify-center flex font-bold text-white">
        zern
      </a>
    </div>
  );
}
