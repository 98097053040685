import React from 'react';
import {Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import Carousel from './Carousel';
import TermsAndCondition from './TermsAndCondition';
import PrivacyPolicy from './PrivacyPolicy';
import {ScrollView} from 'react-native';
import NavBar from './Navbar';
import Footer from './Footer';
import DeleteAccount from './DeleteAccount';

const WebApp = () => {
  return (
    <BrowserRouter>
      <ScrollView>
        <NavBar />
        <Routes>
          <Route path="/" element={<Carousel />} />
          <Route path="/support/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/support/terms-and-condition"
            element={<TermsAndCondition />}
          />
          <Route
            path="/account/delete"
            element={<DeleteAccount />}
          />
        </Routes>
        <Footer />
      </ScrollView>
    </BrowserRouter>
  );
};

export default WebApp;
