import React from 'react';
import 'react-native-gesture-handler';
import {enableLegacyWebImplementation} from 'react-native-gesture-handler';
import WebApp from './web';
enableLegacyWebImplementation(true);

const EntryPoint = () => {
  return (
    <>
      <WebApp />
    </>
  );
};

export default EntryPoint;
